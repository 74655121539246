// ----------------------------------------
// CP Toolbox Icon Font
// ----------------------------------------
$cptoolbox-family: "CPToolbox" !default;
$cptoolbox-apprenticeships: \e900;
$cptoolbox-career-articles: \e901;
$cptoolbox-guidance-calendar: \e902;
$cptoolbox-career-explorer: \e903;
$cptoolbox-new-student-careerfile: \e904;
$cptoolbox-my-careerpad: \e905;
$cptoolbox-student-login: \e906;
$cptoolbox-course-finder: \e907;
$cptoolbox-career-interest-profiler: \e908;
$cptoolbox-disability-info: \e909;
$cptoolbox-world-of-employment: \e90a;
$cptoolbox-grad-job-search: \e90b;
$cptoolbox-employer-insights: \e90c;
$cptoolbox-career-interviews: \e90d;
$cptoolbox-job-applications: \e90e;
$cptoolbox-job-hunting: \e90f;
$cptoolbox-junior-cert-subjects: \e910;
$cptoolbox-labour-market-information: \e911;
$cptoolbox-leaving-cert-next-steps: \e912;
$cptoolbox-media-hub: \e913;
$cptoolbox-my-future: \e914;
$cptoolbox-in-the-news: \e915;
$cptoolbox-personality-profiler: \e916;
$cptoolbox-points-calculator: \e917;
$cptoolbox-postgrad-study: \e918;
$cptoolbox-reach: \e919;
$cptoolbox-career-research: \e91a;
$cptoolbox-scholarships: \e91b;
$cptoolbox-career-sectors: \e91c;
$cptoolbox-self-assessment: \e91d;
$cptoolbox-career-skills: \e91e;
$cptoolbox-subjects-choice: \e91f;
$cptoolbox-study-skills-test: \e920;
$cptoolbox-careersportal-training-dates: \e921;
$cptoolbox-videos: \e922;
$cptoolbox-workxperience: \e923;
$cptoolbox-world-of-work: \e924;
$cptoolbox-subject-grades: \e925;
$cptoolbox-graduate-recruitment: \e926;
$cptoolbox-experts-employers: \e927;
$cptoolbox-college-profiles: \e928;
$cptoolbox-colour-blind-test: \e929;
$cptoolbox-personal-action-plan: \e92a;
$cptoolbox-career-docs: \e92b;
$cptoolbox-career-sectors-profiler: \e92c;
$cptoolbox-career-skills-alt: \e92d;
$cptoolbox-multiple-intelligences: \e92e;
$cptoolbox-guidanceinterview: \e92f;
$cptoolbox-careerawareness: \e930;
$cptoolbox-high-five: \e931;
$cptoolbox-what-inpires-me: \e932;
$cptoolbox-dream-ambitions: \e933;
$cptoolbox-my-talents: \e934;
$cptoolbox-college-open-dates: \e935;
$cptoolbox-careers-fairs-events: \e936;
$cptoolbox-personal-career-values: \e937;
$cptoolbox-aptitudes: \e938;
$cptoolbox-enterprise-skills: \e939;
$cptoolbox-learning-styles: \e93a;
$cptoolbox-exam-targets: \e93b;
$cptoolbox-study-skills-questionnaire: \e93c;
$cptoolbox-diary-work-placement: \e93d;
$cptoolbox-career-investigation: \e93e;
$cptoolbox-enterprise-report: \e93f;
$cptoolbox-summary-report: \e940;
$cptoolbox-my-own-place: \e941;
$cptoolbox-personal-statments: \e942;
$cptoolbox-money-matters: \e943;
$cptoolbox-cao-choices: \e944;
$cptoolbox-plc-choices: \e945;
$cptoolbox-sector-investigation: \e946;
$cptoolbox-languages-connect: \e947;
$cptoolbox-europe: \e948;

@font-face {
    font-family: '#{$cptoolbox-family}';
    src: url('#{$fontpath}/#{$cptoolbox-family}.eot?7qchjf');
    src: url('#{$fontpath}/#{$cptoolbox-family}.eot?7qchjf#iefix') format('embedded-opentype'),
    url('#{$fontpath}/#{$cptoolbox-family}.ttf?7qchjf') format('truetype'),
    url('#{$fontpath}/#{$cptoolbox-family}.woff?7qchjf') format('woff'),
    url('#{$fontpath}/#{$cptoolbox-family}.svg?7qchjf##{$cptoolbox-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="cptoolbox-"],
[class*=" cptoolbox-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$cptoolbox-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.cptoolbox-apprenticeships {
    &:before {
      content: unicode($cptoolbox-apprenticeships); 
    }
  }
  .cptoolbox-career-articles {
    &:before {
      content: unicode($cptoolbox-career-articles); 
    }
  }
  .cptoolbox-guidance-calendar {
    &:before {
      content: unicode($cptoolbox-guidance-calendar); 
    }
  }
  .cptoolbox-career-explorer {
    &:before {
      content: unicode($cptoolbox-career-explorer); 
    }
  }
  .cptoolbox-new-student-careerfile {
    &:before {
      content: unicode($cptoolbox-new-student-careerfile); 
    }
  }
  .cptoolbox-my-careerpad {
    &:before {
      content: unicode($cptoolbox-my-careerpad); 
    }
  }
  .cptoolbox-student-login {
    &:before {
      content: unicode($cptoolbox-student-login); 
    }
  }
  .cptoolbox-course-finder {
    &:before {
      content: unicode($cptoolbox-course-finder); 
    }
  }
  .cptoolbox-career-interest-profiler {
    &:before {
      content: unicode($cptoolbox-career-interest-profiler); 
    }
  }
  .cptoolbox-disability-info {
    &:before {
      content: unicode($cptoolbox-disability-info); 
    }
  }
  .cptoolbox-world-of-employment {
    &:before {
      content: unicode($cptoolbox-world-of-employment); 
    }
  }
  .cptoolbox-grad-job-search {
    &:before {
      content: unicode($cptoolbox-grad-job-search); 
    }
  }
  .cptoolbox-employer-insights {
    &:before {
      content: unicode($cptoolbox-employer-insights); 
    }
  }
  .cptoolbox-career-interviews {
    &:before {
      content: unicode($cptoolbox-career-interviews); 
    }
  }
  .cptoolbox-job-applications {
    &:before {
      content: unicode($cptoolbox-job-applications); 
    }
  }
  .cptoolbox-job-hunting {
    &:before {
      content: unicode($cptoolbox-job-hunting); 
    }
  }
  .cptoolbox-junior-cert-subjects {
    &:before {
      content: unicode($cptoolbox-junior-cert-subjects); 
    }
  }
  .cptoolbox-labour-market-information {
    &:before {
      content: unicode($cptoolbox-labour-market-information); 
    }
  }
  .cptoolbox-leaving-cert-next-steps {
    &:before {
      content: unicode($cptoolbox-leaving-cert-next-steps); 
    }
  }
  .cptoolbox-media-hub {
    &:before {
      content: unicode($cptoolbox-media-hub); 
    }
  }
  .cptoolbox-my-future {
    &:before {
      content: unicode($cptoolbox-my-future); 
    }
  }
  .cptoolbox-in-the-news {
    &:before {
      content: unicode($cptoolbox-in-the-news); 
    }
  }
  .cptoolbox-personality-profiler {
    &:before {
      content: unicode($cptoolbox-personality-profiler); 
    }
  }
  .cptoolbox-points-calculator {
    &:before {
      content: unicode($cptoolbox-points-calculator); 
    }
  }
  .cptoolbox-postgrad-study {
    &:before {
      content: unicode($cptoolbox-postgrad-study); 
    }
  }
  .cptoolbox-reach {
    &:before {
      content: unicode($cptoolbox-reach); 
    }
  }
  .cptoolbox-career-research {
    &:before {
      content: unicode($cptoolbox-career-research); 
    }
  }
  .cptoolbox-scholarships {
    &:before {
      content: unicode($cptoolbox-scholarships); 
    }
  }
  .cptoolbox-career-sectors {
    &:before {
      content: unicode($cptoolbox-career-sectors); 
    }
  }
  .cptoolbox-self-assessment {
    &:before {
      content: unicode($cptoolbox-self-assessment); 
    }
  }
  .cptoolbox-career-skills {
    &:before {
      content: unicode($cptoolbox-career-skills); 
    }
  }
  .cptoolbox-subjects-choice {
    &:before {
      content: unicode($cptoolbox-subjects-choice); 
    }
  }
  .cptoolbox-study-skills-test {
    &:before {
      content: unicode($cptoolbox-study-skills-test); 
    }
  }
  .cptoolbox-careersportal-training-dates {
    &:before {
      content: unicode($cptoolbox-careersportal-training-dates); 
    }
  }
  .cptoolbox-videos {
    &:before {
      content: unicode($cptoolbox-videos); 
    }
  }
  .cptoolbox-workxperience {
    &:before {
      content: unicode($cptoolbox-workxperience); 
    }
  }
  .cptoolbox-world-of-work {
    &:before {
      content: unicode($cptoolbox-world-of-work); 
    }
  }
  .cptoolbox-subject-grades {
    &:before {
      content: unicode($cptoolbox-subject-grades); 
    }
  }
  .cptoolbox-graduate-recruitment {
    &:before {
      content: unicode($cptoolbox-graduate-recruitment); 
    }
  }
  .cptoolbox-experts-employers {
    &:before {
      content: unicode($cptoolbox-experts-employers); 
    }
  }
  .cptoolbox-college-profiles {
    &:before {
      content: unicode($cptoolbox-college-profiles); 
    }
  }
  .cptoolbox-colour-blind-test {
    &:before {
      content: unicode($cptoolbox-colour-blind-test); 
    }
  }
  .cptoolbox-personal-action-plan {
    &:before {
      content: unicode($cptoolbox-personal-action-plan); 
    }
  }
  .cptoolbox-career-docs {
    &:before {
      content: unicode($cptoolbox-career-docs); 
    }
  }
  .cptoolbox-career-sectors-profiler {
    &:before {
      content: unicode($cptoolbox-career-sectors-profiler); 
    }
  }
  .cptoolbox-career-skills-alt {
    &:before {
      content: unicode($cptoolbox-career-skills-alt); 
    }
  }
  .cptoolbox-multiple-intelligences {
    &:before {
      content: unicode($cptoolbox-multiple-intelligences); 
    }
  }
  .cptoolbox-guidanceinterview {
    &:before {
      content: unicode($cptoolbox-guidanceinterview); 
    }
  }
  .cptoolbox-careerawareness {
    &:before {
      content: unicode($cptoolbox-careerawareness); 
    }
  }
  .cptoolbox-high-five {
    &:before {
      content: unicode($cptoolbox-high-five); 
    }
  }
  .cptoolbox-what-inpires-me {
    &:before {
      content: unicode($cptoolbox-what-inpires-me); 
    }
  }
  .cptoolbox-dream-ambitions {
    &:before {
      content: unicode($cptoolbox-dream-ambitions); 
    }
  }
  .cptoolbox-my-talents {
    &:before {
      content: unicode($cptoolbox-my-talents); 
    }
  }
  .cptoolbox-college-open-dates {
    &:before {
      content: unicode($cptoolbox-college-open-dates); 
    }
  }
  .cptoolbox-careers-fairs-events {
    &:before {
      content: unicode($cptoolbox-careers-fairs-events); 
    }
  }
  .cptoolbox-personal-career-values {
    &:before {
      content: unicode($cptoolbox-personal-career-values); 
    }
  }
  .cptoolbox-aptitudes {
    &:before {
      content: unicode($cptoolbox-aptitudes); 
    }
  }
  .cptoolbox-enterprise-skills {
    &:before {
      content: unicode($cptoolbox-enterprise-skills); 
    }
  }
  .cptoolbox-learning-styles {
    &:before {
      content: unicode($cptoolbox-learning-styles); 
    }
  }
  .cptoolbox-exam-targets {
    &:before {
      content: unicode($cptoolbox-exam-targets); 
    }
  }
  .cptoolbox-study-skills-questionnaire {
    &:before {
      content: unicode($cptoolbox-study-skills-questionnaire); 
    }
  }
  .cptoolbox-diary-work-placement {
    &:before {
      content: unicode($cptoolbox-diary-work-placement); 
    }
  }
  .cptoolbox-career-investigation {
    &:before {
      content: unicode($cptoolbox-career-investigation); 
    }
  }
  .cptoolbox-enterprise-report {
    &:before {
      content: unicode($cptoolbox-enterprise-report); 
    }
  }
  .cptoolbox-summary-report {
    &:before {
      content: unicode($cptoolbox-summary-report); 
    }
  }
  .cptoolbox-my-own-place {
    &:before {
      content: unicode($cptoolbox-my-own-place); 
    }
  }
  .cptoolbox-personal-statments {
    &:before {
      content: unicode($cptoolbox-personal-statments); 
    }
  }
  .cptoolbox-money-matters {
    &:before {
      content: unicode($cptoolbox-money-matters); 
    }
  }
  .cptoolbox-cao-choices {
    &:before {
      content: unicode($cptoolbox-cao-choices); 
    }
  }
  .cptoolbox-plc-choices {
    &:before {
      content: unicode($cptoolbox-plc-choices); 
    }
  }
  .cptoolbox-sector-investigation {
    &:before {
      content: unicode($cptoolbox-sector-investigation); 
    }
  }
  .cptoolbox-languages-connect {
    &:before {
      content: unicode($cptoolbox-languages-connect); 
    }
  }  
  .cptoolbox-europe {
    &:before {
      content: unicode($cptoolbox-europe); 
    }
  }